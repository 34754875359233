<template>
  <div class="calendar">
    <image-header :text="$t('calendar.image-header')">
      <img style="object-position: center" src="@/assets/header_images/12.jpeg">
    </image-header>
    <content-sheet>
      <Calendar />
    </content-sheet>
    <!-- <news-article title="Hello" content="<h1>Hello duuudes</h1>" date="30 Jan, 2022"/> -->
  </div>
</template>

<script>
import ContentSheet from '../components/ContentSheet.vue'
import ImageHeader from '../components/ImageHeader.vue'
import Calendar from '../components/Calendar.vue'

export default {
  components: {
    ContentSheet,
    ImageHeader,
    Calendar,
  },
  metaInfo() {
    return {
      title: this.$t('navbar.calendar')
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
}
</script>

<style>

</style>